import { graphql } from 'gatsby';
import React from 'react';
import SEO from '@components/molecules/SEO';
import Blocks from '@components/blocks/Blocks';
import Layout from '@layouts/Layout';
import TopFixedSection from '@components/organisms/TopFixedSection';
import BonusSubImageDesktop from '@images/bonus_form_desktop.webp';
import SubscriptionForm from '@components/molecules/Forms/SubscriptionForm';
import BonusSubImageMobile from '@images/bonus_form_mobile.webp';
import HeroSection from '@components/organisms/HeroSection';
import useToggle from '@hooks/useToggle';
import CalendarSection from '@components/organisms/CalendarSection';
import { removeEmptyEntries } from '@helpers/removeEmptyEntries';
import { useContext } from 'react';
import { SiteContext } from '@store/Provider';
import ShareSection from '@components/molecules/ShareSection';
import CommentSection from '@components/molecules/CommentSection/CommentSection';
import { useScrollToTop } from '@hooks/useScrollToTop';
import { LazyLoadComponent } from 'react-lazy-load-image-component';

const Page = ({
  data: {
    allWp: {
      nodes
    },
    wpCampaign,
    wp: {
      siteSettings: {
        siteSettings: {
          userLoginInformation,
          userLoginRegisterButton,
          commentsHeading,
          commentsSectionName,
          blogPostsActionButton,
          relatedPostsHeading,
          relatedPostsSectionName,
          eventsSettingsAccordionTitle,
          menuText,
          menuLogo,
          campaignsForm,
          campaignsFormErrorMessages,
          campaignsFormPrivacyPolicyLink,
          campaignsFormPrivacyPolicyText,
          campaignsFormTitle,
        },
      },
    },
  },
}) => {
  const isCampaignRegistrationActive =
    new Date(wpCampaign.campaignMeta.registerEndDate).getTime() > Date.now();
  const { isLoggedIn } = useContext(SiteContext);
  const language = 'bg';
  const currentLanguage = language ? language.slug : 'en';
  const { toggled, handleToggleClick } = useToggle(false);
  const filteredArray = removeEmptyEntries(wpCampaign.blocks);

  useScrollToTop();

  return (
    <Layout
      translations={[]}
      currentLanguage={currentLanguage}
      lang={language.locale}
      hideParallax={!filteredArray || filteredArray?.length === 0}
    >
      <SEO
        title={wpCampaign.seo?.title}
        uri={wpCampaign.uri}
        lang={language.locale}
        headJson={wpCampaign.seo?.headJSON}
        translations={[]}
      />
      <TopFixedSection
        menuText={menuText}
        menuLogo={menuLogo}
        handleToggleClick={handleToggleClick}
        toggled={toggled}
        generalSettingsUrl={nodes[0].allSettings.generalSettingsUrl}
      />
      <HeroSection
        content={{
          name: `${wpCampaign.campaignCategories.nodes[0].name}, ${new Date(
            wpCampaign.date,
          ).toDateString()}`,
          excerpt: wpCampaign.excerpt,
          title: wpCampaign.title,
        }}
        heroImage={wpCampaign.featuredImage?.node.publicUrl}
        heroImageMobile={wpCampaign.featuredImage?.node.publicUrl}
        postScroll
        toggled={toggled}
        withShareButton
      />
      <Blocks
        blocks={filteredArray}
        {...(isCampaignRegistrationActive && {
          campaignForm: {
            campaignsForm,
            campaignsFormErrorMessages,
            campaignsFormPrivacyPolicyLink,
            campaignsFormPrivacyPolicyText,
            campaignsFormTitle,
          },
        })}
        eventsSettingsAccordionTitle={eventsSettingsAccordionTitle}
      />
      {isCampaignRegistrationActive && (
        <SubscriptionForm
          title={campaignsFormTitle}
          errors={campaignsFormErrorMessages}
          privacy={{
            text: campaignsFormPrivacyPolicyText,
            link: campaignsFormPrivacyPolicyLink.url,
            linkText: campaignsFormPrivacyPolicyLink.title,
          }}
          buttonText={campaignsForm.button}
          inputs={[
            { label: campaignsForm.firstName, type: 'text', name: 'firstName' },
            { label: campaignsForm.lastName, type: 'text', name: 'lastName' },
            { label: campaignsForm.email, type: 'text', name: 'email' },
          ]}
          imageDesktop={BonusSubImageDesktop}
          imageMobile={BonusSubImageMobile}
          checkboxText={`Кампания: ${wpCampaign.campaignCategories.nodes[0].name}`}
          checkBoxType="hidden"
        />
      )}
      <ShareSection spacing="mx-10 sm:mx-[15%] my-20 md:my-36" />
      {wpCampaign.commentStatus === 'open' && (
        <LazyLoadComponent>
          <CommentSection
            userLoginInformation={userLoginInformation}
            userLoginRegisterButton={userLoginRegisterButton}
            postId={wpCampaign.databaseId}
            comments={wpCampaign.comments.nodes.filter(
              (comment) => !comment.parentId,
            )}
            commentsHeading={commentsHeading}
            commentsSectionName={commentsSectionName}
            length={wpCampaign.commentCount}
            isLoggedIn={isLoggedIn}
          />
        </LazyLoadComponent>
      )}
      {wpCampaign.campaignMeta.relatedPosts && (
        <CalendarSection
          blogPostsActionButton={blogPostsActionButton}
          cards={wpCampaign.campaignMeta.relatedPosts.map((post) => post.post)}
          content={{
            name: relatedPostsSectionName,
            title: relatedPostsHeading,
          }}
          initialSlide={0}
          id="calendar-section"
          selectCardStyles="regularCardStyles"
        />
      )}
    </Layout>
  );
};

export default Page;

export const query = graphql`
  query CAMPAIGN_PAGE($id: String!) {
    allWp {
      nodes {
        allSettings {
          generalSettingsUrl
        }
      }
    }
    wpCampaign(id: { eq: $id }) {
      commentStatus
      commentCount
      comments {
        nodes {
          databaseId
          date
          content
          id
          replies {
            nodes {
              date
              content
              id
              author {
                node {
                  avatar {
                    url
                  }
                  name
                }
              }
            }
          }
          parentId
          author {
            node {
              avatar {
                url
              }
              name
            }
          }
        }
      }
      databaseId
      uri
      seo {
        headJSON
        title
      }
      title
      excerpt
      date
      campaignCategories {
        nodes {
          name
          slug
        }
      }
      campaignMeta {
        registerEndDate
      }
      campaignMeta {
        relatedPosts {
          post {
            ... on WpPost {
              postMeta {
                blogCardSize
                blogPostType
                blogPostStatus
              }
              id
              uri
              categories {
                nodes {
                  slug
                  name
                }
              }
              date(formatString: "D/M/yyyy")
              excerpt
              title
              featuredImage {
                node {
                  publicUrl
                }
              }
            }
          }
        }
      }
      featuredImage {
        node {
          publicUrl
        }
      }
      ...BlocksContentCampaign
    }
    wp {
      siteSettings {
        siteSettings {
          userLoginInformation
          userLoginRegisterButton {
            title
            url
          }
          commentsHeading
          commentsSectionName
          blogPostsActionButton
          relatedPostsHeading
          relatedPostsSectionName
          eventsSettingsAccordionTitle
          menuText
          menuLogo {
            altText
            mediaItemUrl
          }
          campaignsForm {
            firstName
            lastName
            email
            button
            campaignType
          }
          campaignsFormErrorMessages {
            emptyField
            invalidField
            requiredField
          }
          campaignsFormTitle
          campaignsFormPrivacyPolicyText
          campaignsFormPrivacyPolicyLink {
            target
            title
            url
          }
        }
      }
    }
  }
`;
